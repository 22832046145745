<template>
  <div class="content flex">
    <div class="edit">
      <el-form :model="edit"
               :rules="rules"
               ref="ruleForm"
               class="tb-form"
               label-width="140px"
               label-position="right">
        <el-form-item label="品牌名称："
                      prop="name">
          <el-input v-model="edit.name"
                    placeholder="请输入品牌名称"></el-input>
        </el-form-item>
        <el-form-item label="品牌简介："
                      prop="desc">
          <el-input v-model="edit.desc"
                    type="textarea"
                    placeholder="请输入品牌简介，最多300字"
                    :maxlength="300"
                    :autosize="{ minRows: 3, maxRows: 8}"></el-input>
        </el-form-item>
        <el-form-item label="品牌分类："
                      v-if="false">
          <Select :canSearch="false"
                  :selects="categroys"
                  @select="oneSelect">
            <template v-slot:head>设置主营类目</template>
          </Select>
        </el-form-item>
        <el-form-item label="上传品牌logo："
                      prop="logo">
          <template>
            <div class="upload brandlogo">
              <Upload id="logo"
                      src="brand"
                      @success="onLogoUpload">
                <div class="logo"
                     v-if="edit.logo">
                  <img :src="edit.logo"
                       alt="logo"
                       width="100%">
                  <span class="exchange">更换图片</span>
                </div>
                <div class="upload-box flex-center"
                     v-else>
                  <i class="el-icon-plus"></i>
                  <span class="text">点击上传</span>
                </div>
              </Upload>
            </div>
            <span class="tips">建议logo比例 1:1，且大小 &lt;= 50kb，支持jpg、png格式</span>
          </template>
        </el-form-item>
        <el-form-item label="上传品牌头图："
                      prop="headimg"
                      v-if="false">
          <template>
            <div class="upload brandhead">
              <Upload id="headimg"
                      src="brand"
                      @success="onHeadimgUpload">
                <div class="logo"
                     v-if="edit.headimg">
                  <img :src="edit.headimg"
                       alt="headimg"
                       width="100%">
                  <span class="exchange">更换图片</span>
                </div>
                <div class="upload-box flex-center"
                     v-else>
                  <img src="../../assets/icon/brand_upload.png"
                       width="48px"
                       alt="upload">
                  <span class="text">点击上传品牌头图</span>
                </div>
              </Upload>
            </div>
            <span class="tips">建议图片比例 8:3，且大小 &lt;=300kb，支持jpg、png格式</span>
          </template>
        </el-form-item>
        <el-form-item label="营业状态：">
          <el-radio-group v-model="edit.status">
            <el-radio :label="1">营业中</el-radio>
            <el-radio :label="0">暂停营业</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onConfirm">确定</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="demo"
         v-if="false">
      <h3 class="title">品牌信息模板示例</h3>
      <img src="https://image.topboom0912.com/admin_static_img/demo/brand.png"
           alt="demo"
           width="298px">
    </div>
  </div>
</template>

<script>
import Select from '@/components/Select'
import Upload from '@/components/Upload'
export default {
  name: 'BrandAdd',
  components: {
    Upload,
    Select
  },
  data () {
    return {
      id: null,
      edit: {
        name: '',
        desc: '',
        status: 1,
        logoUrl: {
          w: 100,
          h: 100,
          url: ''
        },
        img: {
          w: 100,
          h: 100,
          url: ''
        },
        logo: '',
        headimg: ''
      },
      rules: {
        name: [{ required: true, message: '请输入名称' }],
        desc: [{ required: true, message: '请输入简介' }],
        logo: [{ required: true, message: '请上传logo' }],
        headimg: [{ required: true, message: '请上传头图' }]
      },
      detail: null,
      categroys: [],
      choose: ''
    }
  },
  mounted () {
    this.id = this.$route.query.id * 1 || null
    if (this.id) {
      this.init()
    }
  },
  methods: {
    onLogoUpload (url) {
      this.edit.logo = url
    },
    onHeadimgUpload (url) {
      this.edit.headimg = url
    },
    async init () {
      const detail = await this.getDetail()
      this.edit.name = detail.brandName
      this.edit.desc = detail.brandDesc
      this.edit.logo = this.$img(detail.logoUrl)
      detail.logoUrl = JSON.parse(detail.logoUrl)
      this.edit.headimg = this.$img(detail.img)
      detail.img = JSON.parse(detail.img)
      this.edit.status = detail.status
      this.detail = detail
      this.choose = detail.mainCategoryId
      this.categroys = await this.getMainCategroy(detail.id)
    },
    getDetail () {
      const params = {
        id: this.id
      }
      const url = 'admin/brand/detail'
      return new Promise(resolve => {
        this.$https.get(url, { params }).then(res => {
          resolve(res)
        })
      })
    },
    getMainCategroy (id) {
      const url = 'admin/brand/category/list'
      const params = {
        id
      }
      return new Promise(resolve => {
        this.$https.get(url, { params }).then(res => {
          if (!res) return
          const list = []
          res.forEach(item => {
            item.value = item.id
            item.label = item.category_name
            item.checked = item.id === this.choose
            if (item.status === 1) list.push(item)
          })
          resolve(list)
        })
      })
    },
    async onConfirm () {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      const { name, desc, status, logo, headimg } = this.edit
      this.edit.logoUrl.url = logo
      this.edit.img.url = headimg
      const params = {
        brand_name: name,
        logo_url: JSON.stringify(this.edit.logoUrl),
        img: JSON.stringify(this.edit.img),
        brand_desc: desc,
        main_category_id: this.choose,
        status,
        id: this.id
      }
      const url = 'admin/brand/edit'
      const data = await this.$https.post(url, params)
      if (data) {
        this.$message.success(this.id ? '编辑成功' : '添加成功')
        this.$router.go(-1)
      }
    },
    oneSelect (e) {
      this.choose = e
      this.categroys.forEach(item => item.checked = item.value === e)
    },
    onCancel () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.edit {
  padding-right: 70px;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  .tb-form {
    width: 610px;
    .upload {
      display: flex;
      flex-direction: column;
      line-height: 1;
      &-box {
        width: 100%;
        flex-direction: column;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        cursor: pointer;
        .text {
          padding-top: 8px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .logo {
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        .exchange {
          position: absolute;
          display: block;
          width: 100%;
          height: 28px;
          line-height: 28px;
          bottom: 0;
          left: 0;
          font-size: 12px;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.45);
          text-align: center;
        }
      }
    }
    .brandlogo {
      width: 104px;
      .logo,
      .upload-box {
        height: 104px;
      }
    }
    .brandhead {
      width: 375px;
      .logo,
      .upload-box {
        height: 195px;
      }
    }
    .tips {
      padding-top: 8px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
.demo {
  padding-left: 70px;
  .title {
    margin: 0;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
